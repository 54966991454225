<i18n>
{
  "de": {
    "heatingAge": "Alter Heizung",
    "wallsAge": "Alter Fassade",
    "windowsAge": "Alter Fenster",
    "basementAge": "Alter Kellerdecke",
    "roofAge": "Alter Dach",
    "years": "Jahr | Jahre",
    "new": "Neu",
    "lifecyclePopupTitle": "Zustand Gebäudehülle und Heizung",
    "unknownCanton": "Standort unbekannt",
    "missingArea": "Fläche unbekannt",
    "isBeingCalculated": "wird berechnet ...",
    "statusColors": {
      "PLANNER": "#188A02",
      "REPORTER": "#15A3C0",
      "ARCHIVED": "#777777"
    },
    "statusBackgroundColors": {
      "PLANNER": "#E8F5E5",
      "REPORTER": "#E5F2F5",
      "ARCHIVED": "#F1F1F1"
    },
    "statusChangePendingText": {
      "PENDING_REPORTER": "Nächste Lizenzperiode: Reporter",
      "PENDING_ARCHIVED": "Nächste Lizenzperiode: Archiviert"
    },
    "status": {
      "PLANNER": "Planer",
      "REPORTER": "Reporter",
      "ARCHIVED": "Archiviert"
    },
    "newIndicatorsCannotBeUsedWithOldCalucation": "Gewisse Emissionsindikatoren (z.B. Scope 3 Emissionen) werden erst korrekte Informationen liefern, wenn alle Gebäude des Portfolios neu berechnet wurden. Dieses Gebäude hat veraltete Berechnungen."
  }
}
</i18n>

<template>
  <li class="c-portfolio-list-item" :class="{ 'c-portfolio-list-item--table': displayMode === 'table' }">
    <router-link :to="{ name: 'buildingDetails', params: { portfolio_id: portfolio.id, building_id: building.id } }">
      <section>
        <header>
          <!-- Id -->
          <div>
            <!-- Double wrapping to make sure the nowrap is honored -->
            <div class="portfolio-list-item-queue">
              <BuildingQueuedIndicator
                v-if="this.buildingSimulationQueue(portfolio.id).includes(building.id)"
                :text="$tc('isBeingCalculated')"
              />
              <VPopover trigger="hover" placement="right">
                <span
                  class="building-status"
                  :style="{ backgroundColor: $t(`statusBackgroundColors.${building.status}`) }"
                >
                  <span
                    class="building-status__dot"
                    :style="{ backgroundColor: $t(`statusColors.${building.status}`) }"
                  />
                </span>

                <template slot="popover">
                  <header>
                    <span v-if="building.status_change_pending">
                      {{
                        `${$t(`status.${building.status}`)} (${$t(
                          `statusChangePendingText.${building.status_change_pending}`
                        )})`
                      }}
                    </span>
                    <span v-else>
                      {{ $t(`status.${building.status}`) }}
                    </span>
                  </header>
                </template>
              </VPopover>
              <span v-match-text="searchText" class="building-id">{{ building.identifier }}</span>
            </div>
          </div>

          <!-- Lifespan rings -->
          <VPopover trigger="hover" placement="right">
            <RingIndicator
              :year="building.heating_year"
              :minLifetime="building.heating_replacement_year"
              :maxLifetime="building.heating_replacement_year + 5"
              class="ring-indicator"
            />
            <RingIndicator
              :year="building.walls_year"
              :minLifetime="building.walls_replacement_year"
              :maxLifetime="building.walls_replacement_year + 5"
              class="ring-indicator"
            />
            <RingIndicator
              :year="building.windows_year"
              :minLifetime="building.windows_replacement_year"
              :maxLifetime="building.windows_replacement_year + 5"
              class="ring-indicator"
            />
            <RingIndicator
              :year="building.roof_year"
              :minLifetime="building.roof_replacement_year"
              :maxLifetime="building.roof_replacement_year + 5"
              class="ring-indicator"
            />
            <RingIndicator
              v-if="building.basement_exists"
              :year="building.basement_year"
              :minLifetime="building.basement_replacement_year"
              :maxLifetime="building.basement_replacement_year + 5"
              class="ring-indicator"
            />

            <template slot="popover">
              <header>
                {{ $t('lifecyclePopupTitle') }}
              </header>
              <main class="portfolio-list-item-lifecycle">
                <RingIndicator
                  :year="building.heating_year"
                  :minLifetime="building.heating_replacement_year"
                  :maxLifetime="building.heating_replacement_year + 5"
                />
                {{ $t('heatingAge') }}: {{ heatingAge > 0 ? `${heatingAge} ${$tc('years', heatingAge)}` : $t('new') }}
              </main>
              <main class="portfolio-list-item-lifecycle">
                <RingIndicator
                  :year="building.windows_year"
                  :minLifetime="building.windows_replacement_year"
                  :maxLifetime="building.windows_replacement_year + 5"
                />
                {{ $t('windowsAge') }}: {{ windowsAge > 0 ? `${windowsAge} ${$tc('years', windowsAge)}` : $t('new') }}
              </main>
              <main class="portfolio-list-item-lifecycle">
                <RingIndicator
                  :year="building.walls_year"
                  :minLifetime="building.walls_replacement_year"
                  :maxLifetime="building.walls_replacement_year + 5"
                />
                {{ $t('wallsAge') }}: {{ wallsAge > 0 ? `${wallsAge} ${$tc('years', wallsAge)}` : $t('new') }}
              </main>
              <main class="portfolio-list-item-lifecycle">
                <RingIndicator
                  :year="building.roof_year"
                  :minLifetime="building.roof_replacement_year"
                  :maxLifetime="building.roof_replacement_year + 5"
                />
                {{ $t('roofAge') }}: {{ roofAge > 0 ? `${roofAge} ${$tc('years', roofAge)}` : $t('new') }}
              </main>
              <main v-if="building.basement_exists" class="portfolio-list-item-lifecycle">
                <RingIndicator
                  :year="building.basement_year"
                  :minLifetime="building.basement_replacement_year"
                  :maxLifetime="building.basement_replacement_year + 5"
                />
                {{ $t('basementAge') }}:
                {{ basementAge > 0 ? `${basementAge} ${$tc('years', basementAge)}` : $t('new') }}
              </main>
            </template>
          </VPopover>
        </header>

        <main>
          <!-- Id -->
          <div class="table-element">
            <!-- Double wrapping to make sure the nowrap is honored -->
            <div class="portfolio-list-item-queue">
              <BuildingQueuedIndicator
                v-if="this.buildingSimulationQueue(portfolio.id).includes(building.id)"
                :text="$tc('isBeingCalculated')"
              />
              <VPopover trigger="hover" placement="right">
                <span
                  class="building-status"
                  :style="{ backgroundColor: $t(`statusBackgroundColors.${building.status}`) }"
                >
                  <span
                    class="building-status__dot"
                    :style="{ backgroundColor: $t(`statusColors.${building.status}`) }"
                  />
                </span>

                <template slot="popover">
                  <header>
                    <span v-if="building.status_change_pending">
                      {{
                        `${$t(`status.${building.status}`)} (${$t(
                          `statusChangePendingText.${building.status_change_pending}`
                        )})`
                      }}
                    </span>
                    <span v-else>
                      {{ $t(`status.${building.status}`) }}
                    </span>
                  </header>
                </template>
              </VPopover>
              <span v-match-text="searchText" class="building-id">{{ building.identifier }}</span>
            </div>
          </div>

          <div class="building-description">
            <div v-match-text="searchText" class="address" :title="building.description">
              {{ building.description }}
            </div>
            <div v-match-text="searchText" class="location" :title="`${building.zip_code} ${building.municipality}`">
              {{ building.zip_code }} {{ building.municipality }}
            </div>
            <!--
              Remove/Refactor Errors. Errors are kinda useless, move to a popover on a warning icon for data quality?
            -->
            <!-- <div v-if="building.gwr_state !== 'OK'" class="gis-errors">{{ $t(`_gwrState.${building.gwr_state}`) }}</div> -->
            <!-- <div v-if="building.gwr_state === 'OK' && building.cadastre_state !== 'OK'" class="gis-errors">
              {{ $t(`_cadastreState.${building.cadastre_state}`) }}
            </div>
            <div
              v-if="building.gwr_state === 'OK' && building.geothermal_state && building.geothermal_state !== 'OK'"
              class="gis-errors"
            >
              {{ $t(`_geothermalState.${building.geothermal_state}`) }}
            </div>
            <div
              v-if="building.gwr_state === 'OK' && building.energy_grids_state && building.energy_grids_state !== 'OK'"
              class="gis-errors"
            >
              {{ $t(`_energyGridsState.${building.energy_grids_state}`) }}
            </div>
            <div v-if="building.gwr_state === 'OK' && building.roof_state !== 'OK'" class="gis-errors">
              {{ $t(`_roofState.${building.roof_state}`) }}
            </div> -->
          </div>

          <footer>
            <ul class="building-kpis">
              <!-- Energy area -->
              <li>
                <span v-if="building.data_quality_energy_area === 'GIS_ESTIMATE'" class="numbers missing-data">
                  {{ formatNumber(building.energy_area, 0) }} m²
                </span>
                <span v-else-if="building.energy_area" class="numbers">
                  {{ formatNumber(building.energy_area, 0) }} m²
                </span>
                <div v-else class="missing-data">
                  <DataWarning>{{ $t('missingArea') }}</DataWarning>
                </div>
              </li>

              <!-- CO2 Emissions (Scope 1-2) -->
              <li>
                <span v-if="building.specific_s12e" class="numbers">
                  {{ formatNumber(building.specific_s12e, 0) }} kg CO₂e/m²
                </span>
                <div v-else>-</div>
              </li>

              <!-- End energy -->
              <li>
                <span v-if="building.specific_ee" class="numbers">
                  {{ formatNumber(building.specific_ee, 0) }} kWh/m²
                </span>
                <div v-else>-</div>
              </li>
            </ul>

            <ul class="building-attributes">
              <!-- Use -->
              <li>
                <span
                  v-if="building.main_usage && building.main_usage !== 'UNKNOWN'"
                  :title="$t(`_buildingTags.usage.${building.main_usage}`)"
                >
                  {{ $t(`_buildingTags.usage.${building.main_usage}`) }}
                </span>
                <span v-else class="missing-data">
                  {{ $t('_buildingTags.usage.UNKNOWN') }}
                </span>
              </li>

              <!-- Heating type -->
              <li>
                <span
                  v-if="building.main_heating && building.main_heating !== 'UNKNOWN'"
                  :title="
                    portfolio.getHeatingTypeName(
                      (k) => $t(k),
                      portfolio.heating_types.findIndex((h) => h.name === building.main_heating)
                    )
                  "
                >
                  {{
                    portfolio.getHeatingTypeName(
                      (k) => $t(k),
                      portfolio.heating_types.findIndex((h) => h.name === building.main_heating)
                    )
                  }}
                </span>
                <span v-else class="missing-data">
                  {{ $t('_buildingTags.heating.UNKNOWN') }}
                </span>
              </li>

              <!-- Canton -->
              <li class="canton">
                <span v-if="building.canton && building.canton !== 'UNKNOWN'">
                  {{ building.canton }}
                </span>
                <div v-else class="missing-data">
                  <DataWarning>{{ $t('unknownCanton') }}</DataWarning>
                </div>
              </li>

              <!-- Year built -->
              <li class="year-built">
                <span v-if="building.building_year">
                  {{ building.building_year }}
                </span>
                <span v-else>
                  {{ $t('_buildingTags.decade.UNKNOWN') }}
                </span>
              </li>
            </ul>
          </footer>

          <!--  -->
          <VPopover trigger="hover" placement="right" class="table-element rings">
            <RingIndicator
              :year="building.heating_year"
              :minLifetime="building.heating_replacement_year"
              :maxLifetime="building.heating_replacement_year + 5"
              class="ring-indicator"
            />
            <RingIndicator
              :year="building.walls_year"
              :minLifetime="building.walls_replacement_year"
              :maxLifetime="building.walls_replacement_year + 5"
              class="ring-indicator"
            />
            <RingIndicator
              :year="building.windows_year"
              :minLifetime="building.windows_replacement_year"
              :maxLifetime="building.windows_replacement_year + 5"
              class="ring-indicator"
            />
            <RingIndicator
              :year="building.roof_year"
              :minLifetime="building.roof_replacement_year"
              :maxLifetime="building.roof_replacement_year + 5"
              class="ring-indicator"
            />
            <RingIndicator
              v-if="building.basement_exists"
              :year="building.basement_year"
              :minLifetime="building.basement_replacement_year"
              :maxLifetime="building.basement_replacement_year + 5"
              class="ring-indicator"
            />

            <template slot="popover">
              <header>
                {{ $t('lifecyclePopupTitle') }}
              </header>
              <main class="portfolio-list-item-lifecycle">
                <RingIndicator
                  :year="building.heating_year"
                  :minLifetime="building.heating_replacement_year"
                  :maxLifetime="building.heating_replacement_year + 5"
                />
                {{ $t('heatingAge') }}: {{ heatingAge > 0 ? `${heatingAge} ${$tc('years', heatingAge)}` : $t('new') }}
              </main>
              <main class="portfolio-list-item-lifecycle">
                <RingIndicator
                  :year="building.windows_year"
                  :minLifetime="building.windows_replacement_year"
                  :maxLifetime="building.windows_replacement_year + 5"
                />
                {{ $t('windowsAge') }}: {{ windowsAge > 0 ? `${windowsAge} ${$tc('years', windowsAge)}` : $t('new') }}
              </main>
              <main class="portfolio-list-item-lifecycle">
                <RingIndicator
                  :year="building.walls_year"
                  :minLifetime="building.walls_replacement_year"
                  :maxLifetime="building.walls_replacement_year + 5"
                />
                {{ $t('wallsAge') }}: {{ wallsAge > 0 ? `${wallsAge} ${$tc('years', wallsAge)}` : $t('new') }}
              </main>
              <main class="portfolio-list-item-lifecycle">
                <RingIndicator
                  :year="building.roof_year"
                  :minLifetime="building.roof_replacement_year"
                  :maxLifetime="building.roof_replacement_year + 5"
                />
                {{ $t('roofAge') }}: {{ roofAge > 0 ? `${roofAge} ${$tc('years', roofAge)}` : $t('new') }}
              </main>
              <main v-if="building.basement_exists" class="portfolio-list-item-lifecycle">
                <RingIndicator
                  :year="building.basement_year"
                  :minLifetime="building.basement_replacement_year"
                  :maxLifetime="building.basement_replacement_year + 5"
                />
                {{ $t('basementAge') }}:
                {{ basementAge > 0 ? `${basementAge} ${$tc('years', basementAge)}` : $t('new') }}
              </main>
            </template>
          </VPopover>
        </main>
      </section>
    </router-link>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

import { VPopover } from 'v-tooltip'

import RingIndicator from '@/components/shared/RingIndicator.vue'
import BuildingQueuedIndicator from '@/components/shared/BuildingQueuedIndicator.vue'
import DataWarning from '@/components/shared/DataWarning.vue'

export default {
  components: {
    VPopover,
    RingIndicator,
    BuildingQueuedIndicator,
    DataWarning,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    searchText: {
      type: String,
    },
    displayMode: {
      type: String,
      default: 'cards',
    },
  },

  computed: {
    ...mapGetters({
      buildingSimulationQueue: 'queue/buildingSimulationQueue',
    }),

    wallsAge() {
      return new Date().getFullYear() - this.building.walls_year
    },

    windowsAge() {
      return new Date().getFullYear() - this.building.windows_year
    },

    basementAge() {
      return new Date().getFullYear() - this.building.basement_year
    },

    roofAge() {
      return new Date().getFullYear() - this.building.roof_year
    },

    heatingAge() {
      return new Date().getFullYear() - this.building.heating_year
    },
  },
}
</script>

<style lang="scss" scoped>
.portfolio-list-item-lifecycle {
  display: grid;
  grid-template-columns: 32px 1fr;

  & .ring-indicator {
    width: 24px !important;
  }
}

/* Cards */
.c-portfolio-list-item {
  list-style: none;

  & .missing-data {
    height: 20px;
    color: var(--error-color);
  }

  & .unsure-data {
    color: var(--warning-color);
  }

  & .ring-indicator {
    height: 12px;
    margin-right: 2px;
  }

  & .gis-errors {
    color: var(--warning-color);
    font-size: var(--font-xs);
  }

  & > a {
    display: flex;
    flex-direction: column;

    & > section {
      display: contents;
    }
  }

  & .building-description,
  & .table-element,
  & footer {
    padding: var(--box-padding);
  }

  & header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 10px;
    border-bottom: var(--box-border);
  }

  & .portfolio-list-item-queue {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 6px;
    width: 120px;
    text-align: right;
  }

  & .building-status {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0 2px -2px 0;

    & .building-status__dot {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  & .building-id {
    font-size: var(--font-xs);
    font-weight: 400;
    line-height: 20px;
    padding-top: 2px;
  }

  & .building-description {
    font-size: var(--font-s);
    font-weight: 600;
    height: 60px;
    overflow: hidden;

    & .address {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & footer {
    display: flex;
    flex-direction: column;
    border-top: var(--box-border);
    font-weight: 400;

    & ul {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: var(--spacing-s);
      font-size: var(--font-xs);
      line-height: 20px;
      overflow: hidden;

      & li {
        min-width: 20px;
        overflow: hidden;

        & > span {
          position: relative;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        & sup {
          position: absolute;
          top: -20%;
        }
      }
    }

    & .numbers {
      overflow: visible;
      font-weight: 600;
    }
  }

  & .table-element {
    display: none;
  }

  /* Table line items */
  &.c-portfolio-list-item--table {
    & main {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }

    & header {
      display: none;
    }

    & .portfolio-list-item-queue {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      gap: 6px;
      width: 120px;
      text-align: right;
    }

    & .building-description {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-s);
      font-size: var(--font-xs);
      height: auto;
      width: 20svw;
      min-width: 280px;

      & > * {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & .address {
        flex: 2;
      }
    }

    & footer {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: var(--spacing-s);
      border: none;

      & ul {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: var(--spacing-s);
        font-size: var(--font-xs);
        line-height: 20px;
        overflow: hidden;

        & li {
          overflow: hidden;
          flex: 1;

          & > span {
            position: relative;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          & sup {
            position: absolute;
            top: -20%;
          }
        }

        &.building-kpis {
          width: 360px;
          text-align: right;
        }

        &.building-attributes {
          width: 240px;

          & .canton {
            flex: none;
            width: 24px;
            text-align: center;
          }

          & .year-built {
            flex: none;
            width: 48px;
          }
        }
      }
    }

    & .rings {
      width: 96px;
    }

    & .table-element {
      display: initial;
    }
  }
}
</style>
