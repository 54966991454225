<template>
  <Pie ref="chart" :chart-data="chartData" :chart-options="chartOptions" />
</template>

<script>
import _ from 'lodash'
import { Pie } from 'vue-chartjs/legacy'

export default {
  components: { Pie },

  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
    },
  },

  computed: {
    chartOptions() {
      let options = _.cloneDeep(this.options)
      if (!options?.plugins?.annotation) _.set(options, 'plugins.annotation.annotations', {})
      options.animation = {
        ...(this.options.animation || {}),
        onComplete: () => {
          this.$emit('onChartRender', this.$refs.chart)
        },
      }
      return options
    },
  },

  mounted() {
    this.$emit('onChartMounted', this.$refs.chart)
  },
}
</script>
