<i18n>
{
  "de": {
    "openDetailsLink": "Details anzeigen"
  }
}
</i18n>
<template>
  <div class="c-portfolio-map">
    <MglMap
      :key="key"
      :accessToken="accessToken"
      :mapStyle.sync="mapStyle"
      :scrollZoom="false"
      :center="mapCenter"
      :zoom="mapZoom"
      :attributionControl="false"
      class="map"
      @click="layerClick"
      @zoomend="updateZoom"
      @moveend="updateCenter"
    >
      <!-- Adding navigation control -->
      <MglNavigationControl position="top-left" />

      <!-- Adding GeoJSON layer -->
      <MglGeojsonLayer
        :sourceId="buildingsSource.data.id"
        :source="buildingsSource"
        :layerId="clustersLayer.id"
        :layer="clustersLayer"
      />
      <MglGeojsonLayer
        :sourceId="buildingsSource.data.id"
        :source="buildingsSource"
        :layerId="clusterCountLayer.id"
        :layer="clusterCountLayer"
      />
      <MglGeojsonLayer
        :sourceId="buildingsSource.data.id"
        :source="buildingsSource"
        :layerId="unclusteredPointsLayer.id"
        :layer="unclusteredPointsLayer"
        @mousemove="mouseEvent"
        @mouseleave="mouseEvent"
      />

      <MapboxPopup
        :coordinates="popup.coordinates"
        :showed="popup.showed"
        anchor="bottom"
        class="map-popup"
        @close="onPopupClose"
      >
        <div>
          <template v-if="activeFeature !== undefined">
            <PortfolioListItem
              :key="activeFeature.properties.id"
              :building="buildings.find((b) => b.id === activeFeature.properties.id)"
              :portfolio="portfolio"
            />
          </template>
        </div>
      </MapboxPopup>
    </MglMap>
  </div>
</template>

<script>
import {
  //
  MglMap,
  MglNavigationControl,
  MglGeojsonLayer,
} from 'vue-mapbox'

import { mapGetters, mapActions } from 'vuex'

import MapboxPopup from '@/components/shared/MapboxPopup.vue'
import PortfolioListItem from '@/components/portfolio/PortfolioListItem.vue'

export default {
  components: {
    MglMap,
    MapboxPopup,
    MglNavigationControl,
    PortfolioListItem,
    MglGeojsonLayer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    buildings: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      key: 0,
      accessToken: 'pk.eyJ1IjoibWl2dW5lIiwiYSI6ImNsNWNreTd0cTBpZHkza28xeWo1ODJzamEifQ.Srz2_XsFlucH_7qZzPqkLQ',
      mapStyle: 'mapbox://styles/mivune/cl5clbnp5001a14pvz0v0wugw',
      hoverFeature: undefined,
      activeFeature: undefined,
      popup: {
        coordinates: [8, 48], // This can't be blank! It won't be shown but pick something
        showed: false,
      },
    }
  },

  computed: {
    ...mapGetters({
      __getPortfolioMapZoom: 'ui/getPortfolioMapZoom',
      __getPortfolioMapCenter: 'ui/getPortfolioMapCenter',
    }),

    mapZoom() {
      return this.__getPortfolioMapZoom()
    },

    mapCenter() {
      return this.__getPortfolioMapCenter()
    },

    buildingsSource() {
      var features = []
      for (var i = 0; i < this.buildings.length; i++) {
        if (this.buildings[i].latitude !== undefined && this.buildings[i].longitude !== undefined) {
          features.push({
            id: this.buildings[i].id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [this.buildings[i].longitude, this.buildings[i].latitude],
            },
            properties: {
              id: this.buildings[i].id,
            },
          })
        }
      }
      return {
        type: 'geojson',
        cluster: true,
        clusterMaxZoom: 13, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        data: {
          id: 'buildings',
          type: 'FeatureCollection',
          features: features,
        },
      }
    },

    unclusteredPointsLayer() {
      return {
        id: 'unclusteredPointsLayer',
        type: 'circle',
        source: 'buildings',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-radius': 4,
          'circle-stroke-opacity': 0.3,
          'circle-stroke-color': '#212121',
          'circle-color': '#212121',
          'circle-stroke-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 6],
        },
      }
    },

    clusterCountLayer() {
      return {
        id: 'clusterCountLayer',
        type: 'symbol',
        source: 'buildings',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': '#ffffff',
        },
      }
    },

    clustersLayer() {
      return {
        id: 'clustersLayer',
        type: 'circle',
        source: 'buildings',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#212121',
          'circle-stroke-width': 2,
          'circle-stroke-opacity': 0.3,
          'circle-stroke-color': '#212121',
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            Math.ceil(this.buildings.length / 10),
            30,
            Math.ceil(this.buildings.length / 5) + 1,
            40,
          ],
        },
      }
    },
  },

  activated() {
    // Solves the problem of the map not being shown in the correct size when navigating back to the page
    this.key += 1 // Updating the key forces the component to be re-rendered
  },

  methods: {
    ...mapActions({
      setPortfolioMapZoom: 'ui/setPortfolioMapZoom',
      setPortfolioMapCenter: 'ui/setPortfolioMapCenter',
    }),

    updateZoom(event) {
      // Wait for map to finish zooming transition
      this.$nextTick(() => {
        this.setPortfolioMapZoom(event.map.getZoom())
      })
    },

    updateCenter(event) {
      // Wait for map to finish moving transition
      this.$nextTick(() => {
        this.setPortfolioMapCenter(event.map.getCenter())
      })
    },

    onPopupClose() {
      this.popup.showed = false
      this.activeFeature = undefined
    },

    layerClick(event) {
      let features = event.map.queryRenderedFeatures(event.mapboxEvent.point, {
        layers: ['unclusteredPointsLayer'],
      })
      if (features.length > 0) {
        this.activeFeature = features[0]
        this.$router.push({
          name: 'buildingDetails',
          params: { portfolio_id: this.portfolio.id, building_id: features[0].id },
        })
      } else {
        event.map.getCanvas().style.cursor = ''
      }
    },

    mouseEvent(event) {
      let features = event.map.queryRenderedFeatures(event.mapboxEvent.point, {
        layers: ['unclusteredPointsLayer'],
      })
      if (this.hoverFeature !== undefined) {
        event.map.setFeatureState({ source: 'buildings', id: this.hoverFeature.id }, { hover: false })
        this.popup.showed = false
        event.map.getCanvas().style.cursor = ''
      }
      if (features.length > 0) {
        event.map.getCanvas().style.cursor = 'pointer'
        this.hoverFeature = features[0]
        event.map.setFeatureState({ source: 'buildings', id: this.hoverFeature.id }, { hover: true })
        this.activeFeature = features[0]
        this.popup.coordinates = this.activeFeature.geometry.coordinates
        this.popup.showed = true
      } else {
        event.map.getCanvas().style.cursor = ''
        this.hoverFeature = undefined
      }
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-map {
  height: calc(100% - 65px);

  & .map-popup {
    min-width: 400px;
  }
}
</style>
