<i18n>
{
  "de": {
    "yScale": {
      "COUNT": "Anzahl Liegenschaften",
      "energy_area": "EBF (m²)"
    },
    "xScale": {
      "energy_area": "m² (EBF)",
      "pe_ratio": " ",
      "ee": "kWh/m²",
      "el_production": "kWh/m²",
      "rh_demand": "kWh/m²",
      "rh_ratio": " ",
      "heat_end_energy": "kWh/m²",
      "pe": "kWh/m²",
      "el_demand": "kWh/m²",
      "el_general": "kWh/m²",
      "el_tenants": "kWh/m²",
      "building_year": "Baujahr",
      "heating_year": "Baujahr Heizung",
      "walls_year": "Baujahr Fassade",
      "windows_year": "Baujahr Fenster",
      "roof_year": "Baujahr Dach",
      "rh_calibration": "%",
      "s12e": "kg CO₂e/m²",
      "s123e": "kg CO₂e/m²",
      "per": "kWh/m²"
    }
  }
}
</i18n>

<template>
  <ExportableBarChart v-if="exportable" :chart-data="processedChartData" :options="options" :class="classes" />
  <BarChart v-else :chart-data="processedChartData" :options="options" :class="classes" />
</template>

<script>
import { mapGetters } from 'vuex'

import ExportableBarChart from '@/components/shared/charts/ExportableBarChart.vue'
import BarChart from '@/components/shared/charts/BarChart.vue'

const binConfig = {
  // Emissions Scopes (not sure if scales make sense)
  // Need to move nested initial_state kpis to top level?: 'initial_state.result.computations.totals.s1e': {
  s1e: {
    'min-interval': 5,
    'max-interval': 35,
    'step-size': 5,
    gradient: 'regular',
  },
  s2e: {
    'min-interval': 5,
    'max-interval': 35,
    'step-size': 5,
    gradient: 'regular',
  },
  s3e: {
    'min-interval': 5,
    'max-interval': 35,
    'step-size': 5,
    gradient: 'regular',
  },
  s12e: {
    'min-interval': 5,
    'max-interval': 35,
    'step-size': 5,
    gradient: 'regular',
  },
  s123e: {
    'min-interval': 5,
    'max-interval': 35,
    'step-size': 5,
    gradient: 'regular',
  },
  // Energy (not sure if scales make sense)
  pe: {
    'min-interval': 50,
    'max-interval': 350,
    'step-size': 50,
    gradient: 'regular',
  },
  penr: {
    'min-interval': 50,
    'max-interval': 350,
    'step-size': 50,
    gradient: 'regular',
  },
  per: {
    'min-interval': 50,
    'max-interval': 350,
    'step-size': 50,
    gradient: 'regular',
  },
  pe_ratio: {
    'min-interval': 0,
    'max-interval': 3,
    'step-size': 0.5,
    gradient: 'label',
  },
  ee: {
    'min-interval': 25,
    'max-interval': 175,
    'step-size': 25,
    gradient: 'regular',
  },
  // Others
  building_year: {
    'min-interval': 1900,
    'max-interval': 2020,
    'step-size': 10,
    'format-year': true,
  },
  energy_area: {
    'min-interval': 1000,
    'max-interval': 10000,
    'step-size': 1000,
  },
  rh_demand: {
    'min-interval': 20,
    'max-interval': 140,
    'step-size': 20,
    gradient: 'regular',
  },
  rh_ratio: {
    'min-interval': 0,
    'max-interval': 3,
    'step-size': 0.5,
    gradient: 'label',
  },
  heat_end_energy: {
    'min-interval': 25,
    'max-interval': 200,
    'step-size': 25,
    gradient: 'regular',
  },
  rh_calibration: {
    'min-interval': 30,
    'max-interval': 190,
    'step-size': 20,
  },
  el_demand: {
    'min-interval': 10,
    'max-interval': 95,
    'step-size': 15,
    gradient: 'regular',
  },
  el_tenants: {
    'min-interval': 10,
    'max-interval': 95,
    'step-size': 15,
    gradient: 'regular',
  },
  el_general: {
    'min-interval': 2,
    'max-interval': 14,
    'step-size': 2,
    gradient: 'regular',
  },
  el_production: {
    'min-interval': 5,
    'max-interval': 25,
    'step-size': 5,
    gradient: 'reverse',
  },
  heating_year: {
    'min-interval': 1970,
    'max-interval': 2020,
    'step-size': 5,
    'format-year': true,
  },
  walls_year: {
    'min-interval': 1970,
    'max-interval': 2020,
    'step-size': 5,
    'format-year': true,
  },
  windows_year: {
    'min-interval': 1970,
    'max-interval': 2020,
    'step-size': 5,
    'format-year': true,
  },
  roof_year: {
    'min-interval': 1970,
    'max-interval': 2020,
    'step-size': 5,
    'format-year': true,
  },
}

export default {
  components: {
    BarChart,
    ExportableBarChart,
  },

  props: {
    groupByKey: {
      type: String,
      required: true,
    },
    areaSpecific: {
      type: Boolean,
      default: false,
    },
    byArea: {
      type: Boolean,
      default: false,
    },
    miniChart: {
      type: Boolean,
      default: false,
    },
    highlightValue: {
      type: Number,
    },
    exportable: {
      type: Boolean,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.groupByKey)
    },

    classes() {
      return {
        'histogram-chart': true,
        mini: this.miniChart,
      }
    },

    groupedValues() {
      const self = this
      const groupedValues = this.chartData.reduce(function (res, value) {
        let keyVal
        if (self.areaSpecific) {
          if (value['kpis'][self.groupByKey] || value['kpis'][self.groupByKey] === 0) {
            keyVal = value['kpis'][self.groupByKey] / value.energy_area
          } else {
            keyVal = value[self.groupByKey] / value.energy_area
          }
        } else if (self.groupByKey === 'rh_calibration') {
          // Workaround
          keyVal = value[self.groupByKey] * 100
        } else {
          keyVal = value[self.groupByKey]
        }
        // Below min: Idx = -1
        // Above min: Idx = (max - min) / stepSize
        const keyValIdx = Math.floor(
          Math.max(
            -1,
            Math.min(
              (self.maxInterval - self.minInterval) / self.intervalStepSize,
              (keyVal - self.minInterval) / self.intervalStepSize
            )
          )
        )
        if (!res[keyValIdx]) {
          res[keyValIdx] = { key: keyValIdx, result: 0 }
        }
        if (self.byArea) {
          res[keyValIdx].result += value['energy_area']
        } else {
          res[keyValIdx].result += 1
        }
        return res
      }, {})
      return this.valueIndices.map((i) => (groupedValues[i] === undefined ? 0 : groupedValues[i]))
    },

    valueIndices() {
      return [-1, ...Array(Math.ceil((this.maxInterval - this.minInterval) / this.intervalStepSize + 1)).keys()]
    },

    minInterval() {
      return binConfig[this.groupByKey]['min-interval']
    },

    maxInterval() {
      return binConfig[this.groupByKey]['max-interval']
    },

    intervalStepSize() {
      return binConfig[this.groupByKey]['step-size']
    },

    labels() {
      if (this.groupByKey === 'pe_ratio' || this.groupByKey === 'rh_ratio') {
        return ['+', 'A', 'B', 'C', 'D', 'E', 'F', 'G']
      }
      return this.valueIndices.map((v) => {
        const rangeFrom = this.minInterval + v * this.intervalStepSize
        const rangeTo = this.minInterval + (v + 1) * this.intervalStepSize
        if (binConfig[this.groupByKey]['format-year']) {
          if (rangeFrom < this.minInterval) {
            return `< ${rangeTo}`
          } else if (rangeTo > this.maxInterval) {
            return `> ${rangeFrom}`
          } else {
            return `${rangeFrom} - ${rangeTo - 1}`
          }
        } else {
          const rangeFromF = this.formatNumber(rangeFrom)
          const rangeToF = this.formatNumber(rangeTo)
          if (rangeFrom < this.minInterval) {
            return `< ${rangeToF}`
          } else if (rangeTo > this.maxInterval) {
            return `> ${rangeFromF}`
          } else {
            return `${rangeFromF} - ${rangeToF}`
          }
        }
      })
    },

    yScaleTitle() {
      if (this.byArea) {
        return this.$t(`yScale.energy_area`)
      } else {
        return this.$t(`yScale.COUNT`)
      }
    },

    xScaleTitle() {
      if (this.sustainabilityIndicator === undefined) {
        return this.groupByKey
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`xScale.${this.groupByKey}`)
      }
      return this.sustainabilityIndicator.getSpecificTargetUnit()
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            display: !this.miniChart,
            title: {
              display: !binConfig[this.groupByKey]['format-year'],
              text: this.xScaleTitle,
            },
          },
          yAxis: {
            display: !this.miniChart,
            beginAtZero: true,
            ticks: {
              precision: 0,
              callback: (value) => this.formatNumber(value),
            },
            title: {
              display: true,
              text: this.yScaleTitle,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: !this.miniChart,
          },
          title: {
            display: false,
          },
        },
      }
    },

    colors() {
      // https://stackoverflow.com/questions/17525215/calculate-color-values-from-green-to-red
      function percentageToHsl(percentage, hue0, hue1, l) {
        const hue = percentage * (hue1 - hue0) + hue0
        return 'hsl(' + hue + ', 80%, ' + l + '%)'
      }
      let keyValIdx
      if (this.highlightValue !== undefined) {
        keyValIdx = Math.floor(
          Math.max(
            -1,
            Math.min(
              (this.maxInterval - this.minInterval) / this.intervalStepSize,
              (this.highlightValue - this.minInterval) / this.intervalStepSize
            )
          )
        )
      }
      return this.valueIndices.map((v) => {
        const highlighted = keyValIdx === undefined || keyValIdx === v
        if (this.miniChart) {
          if (highlighted) {
            return '#777777'
          } else {
            return '#dddddd'
          }
        }
        if (binConfig[this.groupByKey]['gradient'] === 'regular') {
          if (highlighted) {
            return percentageToHsl(
              (v + 1) / ((this.maxInterval - this.minInterval) / this.intervalStepSize),
              240,
              360,
              50
            )
          } else {
            return 'hsl(202, 0%, 90%)'
          }
        } else if (binConfig[this.groupByKey]['gradient'] === 'reverse') {
          if (highlighted) {
            return percentageToHsl(
              1 - (v + 1) / ((this.maxInterval - this.minInterval) / this.intervalStepSize),
              240,
              360,
              50
            )
          } else {
            return 'hsl(202, 0%, 90%)'
          }
        } else if (binConfig[this.groupByKey]['gradient'] === 'label') {
          if (highlighted) {
            return [
              'rgba(0, 172, 96, 1.0)',
              'rgba(79, 184, 89, 1.0)',
              'rgba(195, 222, 67, 1.0)',
              'rgba(232, 232, 0, 1.0)',
              'rgba(248, 206, 32, 1.0)',
              'rgba(240, 157, 15, 1.0)',
              'rgba(226, 46, 49, 1.0)',
            ][v === -1 ? 0 : v]
          } else {
            return 'hsl(202, 0%, 90%)'
          }
        } else {
          return highlighted ? 'hsl(202, 92%, 14%)' : 'hsl(202, 92%, 90%)'
        }
      })
    },

    processedChartData() {
      return {
        datasets: [
          {
            label: this.yScaleTitle,
            data: this.groupedValues.map((g) => g.result),
            backgroundColor: this.colors,
          },
        ],
        labels: this.labels,
      }
    },
  },
}
</script>

<style>
.histogram-chart.mini {
  height: 35px;
  width: 50px;
  display: inline-block;
  padding-bottom: 1px;
  border-bottom: 2px solid #eee;
}
</style>
