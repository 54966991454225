<i18n>
{
  "de": {
    "mapButton": "Karte",
    "chartsButton": "Kennzahlen",
    "countButton": "Anzahl",
    "byAreaButton": "Fläche",
    "resetMapButton": "Karte zurücksetzen"
  }
}
</i18n>

<template>
  <div class="c-portfolio-map-and-kpis" :class="{ 'c-portfolio-map-and-kpis--horizontal': layout === 'horizontal' }">
    <!-- Map / KPIs toggle -->
    <div v-sticky="layout === 'vertical'" sticky-z-index="8" class="sticky-buttons">
      <nav class="round-buttons">
        <a href="#" :class="{ selected: mode === 'map' }" @click.prevent="setMode('map')">
          <img src="/icons/map.svg" />
          <span>{{ $t('mapButton') }}</span>
        </a>
        <a href="#" :class="{ selected: mode === 'kpis' }" @click.prevent="setMode('kpis')">
          <img src="/icons/pie-chart.svg" />
          <span>{{ $t('chartsButton') }}</span>
        </a>
      </nav>

      <nav v-if="mode === 'kpis'" class="round-buttons">
        <a href="#" :class="{ selected: kpiOverviewMode === 'count' }" @click.prevent="setKpiOverviewMode('count')">
          <span>{{ $t('countButton') }}</span>
        </a>
        <a href="#" :class="{ selected: kpiOverviewMode === 'area' }" @click.prevent="setKpiOverviewMode('area')">
          <span>{{ $t('byAreaButton') }}</span>
        </a>
      </nav>
    </div>

    <!-- Map -->
    <PortfolioMap v-if="mode === 'map'" :buildings="buildings" :portfolio="portfolio" />
    <!-- Map reset button -->
    <nav v-if="mode === 'map' && !isDefaultMapCenterAndZoom" class="round-buttons floating--bottom">
      <a href="#" @click.prevent="resetMap">
        {{ $t('resetMapButton') }}
      </a>
    </nav>

    <!-- KPIs (Kennzahlen) -->
    <div v-if="mode === 'kpis'" class="portfolio-kpis">
      <PortfolioKpiOverview :portfolio="portfolio" :chart-data="chartData" :show="mode === 'kpis'" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import PortfolioMap from '@/components/portfolio/PortfolioMap.vue'
import PortfolioKpiOverview from '@/components/portfolio/kpi/PortfolioKpiOverview.vue'

export default {
  components: {
    PortfolioMap,
    PortfolioKpiOverview,
  },

  props: {
    portfolio: {
      type: Object,
      // required: true,
    },
    buildings: {
      type: Array,
      required: true,
    },
    layout: {
      type: String, // 'horizontal' or 'vertical'
      default: 'vertical',
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isDefaultMapCenterAndZoom: true,
    }
  },

  computed: {
    ...mapGetters('ui', [
      'getPortfolioMapCenter',
      'getPortfolioMapZoom',
      'getDefaultPortfolioMapCenter',
      'getDefaultPortfolioMapZoom',
    ]),

    mode() {
      return this.$store.state.ui.portfolioMapAndKpisMode
    },

    kpiOverviewMode() {
      return this.$store.state.ui.portfolioKpiOverviewMode
    },

    byArea() {
      return this.kpiOverviewMode === 'area'
    },

    portfolioMapZoom() {
      return this.$store.state.ui.portfolioMapZoom
    },

    portfolioMapCenter() {
      return this.$store.state.ui.portfolioMapCenter
    },
  },

  watch: {
    portfolioMapCenter() {
      this.isDefaultMapCenterAndZoom = this.isDefaultMapCenter()
    },

    portfolioMapZoom() {
      this.isDefaultMapCenterAndZoom = this.isDefaultMapZoom()
    },
  },

  created() {
    // map vs kpis
    const mode = localStorage.getItem('portfolioMapAndKpisMode')
    if (mode) {
      this.$store.dispatch('ui/setPortfolioMapAndKpisMode', mode)
    }
    // count vs area
    const KpiMode = localStorage.getItem('portfolioKpiOverviewMode')
    if (KpiMode) {
      this.$store.dispatch('ui/setPortfolioKpiOverviewMode', KpiMode)
    }
  },

  methods: {
    isDefaultMapCenter() {
      return (
        (this.getPortfolioMapCenter().lat === this.getDefaultPortfolioMapCenter().lat &&
          this.getPortfolioMapCenter().lng === this.getDefaultPortfolioMapCenter().lng) ||
        false
      )
    },

    isDefaultMapZoom() {
      return this.getPortfolioMapZoom() === this.getDefaultPortfolioMapZoom()
    },

    setMode(mode) {
      this.$store.dispatch('ui/setPortfolioMapAndKpisMode', mode)
    },

    setKpiOverviewMode(mode) {
      this.$store.dispatch('ui/setPortfolioKpiOverviewMode', mode)
    },

    resetMap() {
      this.isDefaultMapCenterAndZoom = true
      this.$store.dispatch('ui/resetPortfolioMapCenter')
      this.$store.dispatch('ui/resetPortfolioMapZoom')
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-map-and-kpis {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1200px) {
    display: none;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    overflow-y: auto;
  }

  &.c-portfolio-map-and-kpis--horizontal {
    min-height: initial;
    max-height: initial;
    width: 100svw;
    height: 380px;
    margin: 0 -16px 16px;

    @media screen and (max-width: 1200px) {
      display: block;
    }
  }

  & .sticky-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 12px 16px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }

  & nav.round-buttons.floating--bottom {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    transition: opacity 0.3s ease-in-out;

    @media screen and (max-width: 1200px) {
      bottom: 20px;
    }
  }
}
</style>
