<i18n>
{
  "de": {
    "totalLabel": "Total",
    "buildingTypeTitle": "Übersicht",
    "bubbleTitle": "Bubble",
    "yearTitle": "Baujahr",
    "areaTitle": "Fläche",
    "usageTitle": "Hauptnutzung",
    "heatingTitle": "Heizung",
    "byUsage": "Nach Verwendungszweck",
    "byCarrier": "Nach Energieträger",
    "heatTitle": "Wärme",
    "labelTitle": "Effizienzklassen",
    "rhDemandHistogramTitle": "Nutzenergie Raumwärme",
    "heatEndEnergyHistogramTitle": "Endenergiebedarf Heizung inkl. WW",
    "rhCalibrationHistogramTitle": "Kalibrierung Raumwärme",
    "electricityTitle": "Strom",
    "elDemandHistogramTitle": "Strombedarf Total",
    "elGeneralHistogramTitle": "Allgemeinstrom",
    "elTenantHistogramTitle": "Mieterstrom",
    "elProductionHistogramTitle": "Stromproduktion",
    "availabilityTitle": "Machbarkeit",
    "ewsPieTitle": "Erdwärmesonden",
    "gwPieTitle": "Wärmenutzung Grundwasser",
    "districtPieTitle": "Wärmenetze",
    "componentsTitle": "Bauteile",
    "heatingYearTitle": "Baujahr Heizung",
    "wallsYearTitle": "Baujahr Fassade",
    "windowsYearTitle": "Baujahr Fenster",
    "roofYearTitle": "Baujahr Dach",
    "dataQualityTitle": "Daten"
  }
}
</i18n>

<template>
  <div class="c-portfolio-kpi-overview">
    <Tabs group="portfolio-kpi-tabs">
      <!--  -->
      <Tab
        v-if="portfolio.enabled_features.KPI"
        :key="`buildingTypeTitle`"
        :title="$t('buildingTypeTitle')"
        class="tab"
      >
        <div>
          <h3>{{ $t('heatingTitle') }}</h3>
          <TagPieChart
            key="heatingpiechart"
            :by-area="byArea"
            :group-by-key="'heating'"
            :portfolio="portfolio"
            :chart-data="chartData"
            class="pie-chart"
          />
        </div>
        <div>
          <h3>{{ $t('usageTitle') }}</h3>
          <TagPieChart
            key="usagepiechart"
            :by-area="byArea"
            :group-by-key="'usage'"
            :portfolio="portfolio"
            :chart-data="chartData"
            class="pie-chart"
          />
        </div>
        <div>
          <h3>{{ $t('yearTitle') }}</h3>
          <HistogramChart
            key="buildingyearhistogram"
            :by-area="byArea"
            :group-by-key="'building_year'"
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
        <div>
          <h3>{{ $t('areaTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalEnergyArea) }} m²)</h3>
          <HistogramChart
            key="areahistogram"
            :by-area="byArea"
            :group-by-key="'energy_area'"
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
      </Tab>

      <!--  -->
      <template v-if="portfolio.enabled_features.KPI">
        <Tab
          v-for="indicator in defaultSustainabilityIndicatorIdentifiers"
          :key="indicator"
          :title="getIndicatorName(indicator)"
          class="tab"
        >
          <div v-if="indicator === 'pe'">
            <h3>{{ $t('labelTitle') }}</h3>
            <HistogramChart
              key="pelabelhistogram"
              :by-area="byArea"
              :group-by-key="'pe_ratio'"
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>
              {{ getIndicatorName(indicator) }} ({{ $t('totalLabel') }}:
              {{ formatNumber(totalKpi(indicator) / 1000, 0) }} {{ getBigUnit(indicator) }})
            </h3>
            <HistogramChart
              :key="indicator + '_histogram'"
              :by-area="byArea"
              :group-by-key="indicator"
              area-specific
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>{{ $t('byCarrier') }}</h3>
            <KpiPieChart
              :key="indicator + '_by_carrier'"
              :kpi-type="indicator"
              :group-by-type="'carrier'"
              :portfolio="portfolio"
              :chart-data="chartData"
              class="pie-chart"
            />
          </div>
          <div>
            <h3>{{ $t('byUsage') }}</h3>
            <KpiPieChart
              :key="indicator + '_by_usage'"
              :kpi-type="indicator"
              :group-by-type="'usage'"
              :portfolio="portfolio"
              :chart-data="chartData"
              class="pie-chart"
            />
          </div>
        </Tab>
      </template>

      <!--  -->
      <Tab v-if="portfolio.enabled_features.KPI" :key="'heatTitle'" :title="$t('heatTitle')" class="tab">
        <div>
          <h3>{{ $t('labelTitle') }}</h3>
          <HistogramChart
            key="rhlabelhistogram"
            :by-area="byArea"
            :group-by-key="'rh_ratio'"
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
        <div>
          <h3>
            {{ $t('rhDemandHistogramTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalRh / 1000, 0) }} MWh)
          </h3>
          <HistogramChart
            key="rhhistogram"
            :by-area="byArea"
            :group-by-key="'rh_demand'"
            area-specific
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
        <div>
          <h3>
            {{ $t('heatEndEnergyHistogramTitle') }} ({{ $t('totalLabel') }}:
            {{ formatNumber(totalHeatEndEnergy / 1000, 0) }} MWh)
          </h3>
          <HistogramChart
            key="heat_end_energy_histogram"
            :by-area="byArea"
            :group-by-key="'heat_end_energy'"
            area-specific
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
        <div>
          <h3>{{ $t('rhCalibrationHistogramTitle') }}</h3>
          <HistogramChart
            key="rhcalibhistogram"
            :by-area="byArea"
            :group-by-key="'rh_calibration'"
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
      </Tab>

      <!--  -->
      <Tab v-if="portfolio.enabled_features.KPI" key="electricityTitle" :title="$t('electricityTitle')" class="tab">
        <div>
          <h3>
            {{ $t('elDemandHistogramTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalEl / 1000, 0) }} MWh)
          </h3>
          <HistogramChart
            key="elhistogram"
            :by-area="byArea"
            :group-by-key="'el_demand'"
            area-specific
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
        <div>
          <h3>
            {{ $t('elTenantHistogramTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalElTenant / 1000, 0) }} MWh)
          </h3>
          <HistogramChart
            key="eltenanthistogram"
            :by-area="byArea"
            :group-by-key="'el_tenants'"
            area-specific
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
        <div>
          <h3>
            {{ $t('elGeneralHistogramTitle') }} ({{ $t('totalLabel') }}:
            {{ formatNumber(totalElGeneral / 1000, 0) }} MWh)
          </h3>
          <HistogramChart
            key="elgeneralhistogram"
            :by-area="byArea"
            :group-by-key="'el_general'"
            area-specific
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
        <div>
          <h3>
            {{ $t('elProductionHistogramTitle') }} ({{ $t('totalLabel') }}:
            {{ formatNumber(totalElProduction / 1000, 0) }} MWh)
          </h3>
          <HistogramChart
            :key="'elproductionhistogram'"
            :by-area="byArea"
            :group-by-key="'el_production'"
            area-specific
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
      </Tab>

      <!--  -->
      <Tab v-if="portfolio.enabled_features.KPI" key="componentsTitle" :title="$t('componentsTitle')" class="tab">
        <div>
          <h3>{{ $t('heatingYearTitle') }}</h3>
          <HistogramChart
            key="heatingyearhistogram"
            :by-area="byArea"
            :group-by-key="'heating_year'"
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
        <div>
          <h3>{{ $t('wallsYearTitle') }}</h3>
          <HistogramChart
            key="wallsyearhistogram"
            :by-area="byArea"
            :group-by-key="'walls_year'"
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
        <div>
          <h3>{{ $t('windowsYearTitle') }}</h3>
          <HistogramChart
            key="windowsyearhistogram"
            :by-area="byArea"
            :group-by-key="'windows_year'"
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
        <div>
          <h3>{{ $t('roofYearTitle') }}</h3>
          <HistogramChart
            key="roofyearhistogram"
            :by-area="byArea"
            :group-by-key="'roof_year'"
            :chart-data="chartData"
            class="histogram-chart"
            exportable
          />
        </div>
      </Tab>

      <!--  -->
      <Tab v-if="portfolio.enabled_features.KPI" key="availabilityTitle" :title="$t('availabilityTitle')" class="tab">
        <div>
          <h3>{{ $t('ewsPieTitle') }}</h3>
          <TagPieChart
            key="ewspiechart"
            :by-area="byArea"
            :group-by-key="'feasibility_ews'"
            :portfolio="portfolio"
            :chart-data="chartData"
            class="pie-chart"
          />
        </div>
        <!-- <div>
          <h3>{{ $t('gwPieTitle') }}</h3>
          <TagPieChart
          :by-area="byArea"
          key="gwpiechart"
          :group-by-key="'feasibility_gw'"
          :portfolio="portfolio"
          :chart-data="chartData"
          class="pie-chart"
          />
        </div> -->
        <div>
          <h3>{{ $t('districtPieTitle') }}</h3>
          <TagPieChart
            key="districtpiechart"
            :by-area="byArea"
            :group-by-key="'availability_district'"
            :portfolio="portfolio"
            :chart-data="chartData"
            class="pie-chart"
          />
        </div>
      </Tab>

      <!--  -->
      <Tab key="dataQualityTitle" :title="$t('dataQualityTitle')" class="tab">
        <DataQualityChart key="dataqualitychart" :by-area="byArea" :chart-data="chartData" class="data-quality-chart" />
      </Tab>

      <!--  -->
      <Tab key="bubbleTitle" :title="$t('bubbleTitle')" class="tab">
        <BubbleChartWrapper key="buildingbubble" :chart-data="chartData" class="bubble-chart" @click="onBubbleClick" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Tabs from '@/components/shared/Tabs.vue'
import Tab from '@/components/shared/Tab.vue'
import BubbleChartWrapper from '@/components/portfolio/kpi/charts/BubbleChartWrapper.vue'
import DataQualityChart from '@/components/portfolio/kpi/charts/DataQualityChart.vue'
import TagPieChart from '@/components/portfolio/kpi/charts/TagPieChart.vue'
import KpiPieChart from '@/components/portfolio/kpi/charts/KpiPieChart.vue'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart.vue'

export default {
  components: {
    TagPieChart,
    KpiPieChart,
    DataQualityChart,
    HistogramChart,
    BubbleChartWrapper,
    Tab,
    Tabs,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('portfolio', ['defaultSustainabilityIndicatorIdentifiers']),

    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    mode() {
      return this.$store.state.ui.portfolioKpiOverviewMode
    },

    byArea() {
      return this.mode === 'area'
    },

    totalEnergyArea() {
      return this.chartData.reduce((pv, cv) => pv + cv.energy_area, 0)
    },

    totalRh() {
      return this.chartData.reduce((pv, cv) => pv + cv.rh_demand, 0)
    },

    totalHeatEndEnergy() {
      return this.chartData.reduce((pv, cv) => pv + cv.heat_end_energy, 0)
    },

    totalEl() {
      return this.chartData.reduce((pv, cv) => pv + cv.el_demand, 0)
    },

    totalElTenant() {
      return this.chartData.reduce((pv, cv) => pv + cv.el_tenants, 0)
    },

    totalElGeneral() {
      return this.chartData.reduce((pv, cv) => pv + cv.el_general, 0)
    },

    totalElProduction() {
      return this.chartData.reduce((pv, cv) => pv + cv.el_production, 0)
    },
  },

  methods: {
    onBubbleClick(building) {
      this.$router.push({
        name: 'buildingDetails',
        params: { portfolio_id: this.portfolio.id, building_id: building.id },
      })
    },

    totalKpi(kpiType) {
      return this.chartData.reduce((pv, cv) => {
        return pv + (kpiType in cv.kpis ? cv.kpis[kpiType] : 0)
      }, 0)
    },

    getBigUnit(identifier) {
      return this.__getEnhancedSustainabilityIndicator(identifier)?.getBigTargetUnit()
    },

    getIndicatorName(identifier) {
      return this.__getEnhancedSustainabilityIndicator(identifier)?.name
    },
  },
}
</script>

<style lang="scss" scoped>
.c-portfolio-kpi-overview {
  height: 100%;
  padding: var(--spacing-s);

  & .tab {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--spacing-m);
    justify-content: center;

    & > div {
      width: calc(min(100%, 500px));
    }
  }

  & .grouping-type {
    padding: var(--spacing-s);
    font-style: italic;
  }
}
</style>

<style lang="scss">
.c-portfolio-kpi-overview {
  & .tabs {
    max-width: 100%;
  }

  & .histogram-chart > .chart-container {
    height: 200px;
  }

  & .data-quality-chart > .chart-container {
    height: 300px;
  }

  & .pie-chart > .chart-container {
    height: 200px;
  }

  & h3 {
    text-align: center;
    margin-top: var(--spacing-m);
  }

  & .bubble-chart {
    height: 250px;
  }
}
</style>
