<i18n>
{
  "de": {
    "pageTitle": "Liegenschaften"
  }
}
</i18n>

<template>
  <SplitLayout :portfolio="portfolio" :ready="portfolioReady" class="portfolio-page">
    <template #default>
      <!-- Main Container: List of Properties -->
      <div class="main-container">
        <!-- Page title -->
        <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />

        <!--  -->
        <PortfolioMapAndKpis
          :portfolio="portfolio"
          :buildings="buildingsForList"
          :chart-data="chartData"
          class="main-container__map-and-kpis"
          layout="horizontal"
        />

        <div class="list-controls">
          <div class="list-controls__search">
            <!-- Search bar -->
            <PortfolioSearch :portfolio="portfolio" />
          </div>

          <!-- Filters -->
          <TagFilters :portfolio="portfolio" :tag-groups="portfolioBuildingTags" />
        </div>

        <!-- Properties (Liegenschaften) -->
        <PortfolioList
          :portfolio="portfolio"
          :buildings="buildingsForList"
          :buildings-loading="buildingsForListLoading"
          :search-text="query.search"
        />
      </div>
    </template>

    <template #side-panel>
      <!-- Side Tray: Map or KPI Charts -->
      <PortfolioMapAndKpis
        :portfolio="portfolio"
        :buildings="buildingsForList"
        :chart-data="chartData"
        class="side-tray"
        layout="vertical"
      />
    </template>
  </SplitLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SplitLayout from '@/pages/layouts/SplitLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import PortfolioBuildingTagsMixin from '@/pages/vuex-mixins/PortfolioBuildingTagsMixin.vue'
import ChartDataMixin from '@/pages/vuex-mixins/ChartDataMixin.vue'
import PortfolioBuildingsForListMixin from '@/pages/vuex-mixins/PortfolioBuildingsForListMixin.vue'

import PageTitle from '@/components/shared/PageTitle.vue'
import PortfolioSearch from '@/components/portfolio/PortfolioSearch.vue'
import TagFilters from '@/components/portfolio/filters/TagFilters.vue'
import PortfolioList from '@/components/portfolio/PortfolioList.vue'
import PortfolioMapAndKpis from '@/components/portfolio/PortfolioMapAndKpis.vue'

export default {
  name: 'portfolioBuildings',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioBuildingsForListMixin, // Provides: buildingsForList, buildingsForListLoading
    PortfolioBuildingTagsMixin, // Provides: portfolioBuildingTags, portfolioBuildingTagsLoading, portfolioBuildingTagsLoaded
    ChartDataMixin, // Provides: chartData, chartDataLoading, chartDataLoaded
  ],

  components: {
    SplitLayout,
    PortfolioSearch,
    TagFilters,
    PortfolioList,
    PortfolioMapAndKpis,
    PageTitle,
  },

  data() {
    return {
      pageTitle: null,
    }
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
      getCurrentPortfolioId: 'ui/getCurrentPortfolioId',
    }),
  },

  // // Draft: For page title
  // watch: {
  //   portfolio() {
  //     this.onPortfolioUpdated()
  //   },
  // },

  created() {
    this.resetPortfolioMapCenter()
    this.resetPortfolioMapZoom()
  },

  updated() {
    if (String(this.portfolio_id) !== String(this.getCurrentPortfolioId())) {
      this.resetQuery({ portfolioId: this.portfolio_id })
      this.setCurrentPortfolioId(this.portfolio_id)
    }
  },

  methods: {
    ...mapActions({
      resetPortfolioMapCenter: 'ui/resetPortfolioMapCenter',
      resetPortfolioMapZoom: 'ui/resetPortfolioMapZoom',
      setCurrentPortfolioId: 'ui/setCurrentPortfolioId',
      resetQuery: 'query/reset',
    }),

    // // Draft: For page title
    // onPortfolioUpdated() {
    //   this.pageTitle = `${this.$t('pageTitle')} - ${this.portfolio.name}`
    //   this.$emit('updateHead')
    // },
  },

  // // Draft: For page title
  // head() {
  //   return {
  //     title: () => {
  //       if (this.pageTitle) {
  //         return { inner: `${this.pageTitle}` }
  //       }
  //       if (this.portfolio) {
  //         return { inner: `${this.$t('pageTitle')} - ${this.portfolio.name}` }
  //       }
  //     },
  //   }
  // },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss">
.portfolio-page {
  & > .sim-warning {
    padding: var(--spacing-l);
  }

  & nav.round-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-xs);
    color: var(--highlight-color);

    & a {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: var(--spacing-s);
      border: 2px solid var(--hairline-color);
      background-color: #fff;
      border-radius: 500px;
      line-height: 16px;
      min-height: 40px;
    }

    & a.selected {
      border: 2px solid #333;
    }

    & a img {
      height: 16px;
      width: 16px;
      vertical-align: sub;
    }

    & a img + * {
      margin-left: 5px;
    }

    & a:hover {
      outline: 3px solid #ddd;
      border: 2px solid #333;
    }
  }

  & .portfolio-page-wrapper {
    position: relative;
  }

  & .list-controls {
    & .list-controls__search {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: var(--spacing-xs) 0 var(--spacing-s);
    }
  }

  /* Main page content */

  & .main-container {
    position: relative;
    width: 100%;

    & .main-container__map-and-kpis {
      display: none;

      @media screen and (max-width: 1200px) {
        display: block;
      }
    }
  }
}
</style>
