<i18n>
{
  "de": {
    "sortOptions": {
      "sort": "Sortieren",
      "identifier": "Identifikator",
      "municipality": "Gemeinde",
      "description": "Beschreibung",
      "building_year": "Baujahr",
      "energy_area": "Energiebezugsfläche",
      "ghg": "Scope 1-2 Emissionen",
      "ee": "Endenergie"
    }
  }
}
</i18n>

<template>
  <div class="c-sort-dropdown">
    <div>{{ $t('sortOptions.sort') }}:</div>

    <Menu :buttonText="$t(`sortOptions.${query.sort_order}`)">
      <template #options>
        <MenuItem
          v-for="option in sortOrderOptions"
          :id="`sort-option-${option}`"
          :key="option"
          :label="$t(`sortOptions.${option}`)"
          :type="'label'"
          :value="option"
          @click="updateSortOrder"
        />
      </template>
    </Menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Menu from '@/components/shared/menu/Menu.vue'
import MenuItem from '@/components/shared/menu/MenuItem.vue'

export default {
  components: {
    Menu,
    MenuItem,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),

    sortOrderOptions() {
      return [
        //
        'identifier',
        'municipality',
        'description',
        'building_year',
        'energy_area',
        'ghg',
        'ee',
      ]
    },
  },

  methods: {
    ...mapActions({
      setSortOrder: 'query/setSortOrder',
    }),

    updateSortOrder(sortAttribute) {
      this.setSortOrder({ order: sortAttribute })
    },
  },
}
</script>

<style>
.c-sort-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
}
</style>
