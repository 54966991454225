<i18n>
  {
    "de": {
      "exportCsvButton": "Daten (CSV)",
      "exportPngButton": "Bild (PNG)",
      "showFullLegend": "Gesamte Legende anzeigen...",
      "minimize": "Minimieren",
      "maximize": "Maximieren"
    }
  }
</i18n>

<template>
  <div class="c-exportable-pie-chart">
    <PieChart
      :class="`chart-container${expanded ? ' expanded' : ''}`"
      :chart-data="chartData"
      :options="options"
      @onChartMounted="onChartMounted"
      @onChartRender="onChartRender"
    />
    <div
      ref="exportWrapper"
      class="export-wrapper"
      :style="{ width: `${imageSize.width}px`, height: `${imageSize.height}px` }"
    >
      <canvas ref="exportCanvas" />
    </div>
    <div v-if="canExpand && !expanded" class="expand-info" @click="switchChartView">{{ $t('showFullLegend') }}</div>
    <div class="action-buttons">
      <Button :img-src="'/icons/download.svg'" :text="$t('exportCsvButton')" @click="exportAsCsv" />
      <Button :img-src="'/icons/download.svg'" :text="$t('exportPngButton')" @click="exportAsPng" />
      <Button
        v-if="canExpand"
        :img-src="expanded ? '/icons/minimize-2.svg' : '/icons/maximize-2.svg'"
        :tooltip="expanded ? $t('minimize') : $t('maximize')"
        @click="switchChartView"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import exportService from '@/services/export-service.js'

import PieChart from '@/components/shared/charts/PieChart.vue'
import Button from '@/components/shared/Button.vue'

export default {
  components: {
    PieChart,
    Button,
  },

  props: {
    chartData: {
      type: Object,
    },
    options: {
      type: Object,
    },
    groupingName: {
      // e.g. Usage Type
      type: String,
    },
    imageSize: {
      type: Object,
      default: () => ({
        width: 480,
        height: 360,
      }),
    },
  },

  data() {
    return {
      chartInstance: null,
      expanded: false,
      canExpand: false,
    }
  },

  methods: {
    exportAsCsv() {
      let data = exportService.chartToCsv(this.chartData, this.options)
      exportService.saveCsv(data.join('\n'), 'export.csv')
    },

    exportAsPng() {
      // Render the export wrapper
      this.$refs.exportWrapper.style.display = 'block'

      const { chart } = exportService.chartToExportCanvas(
        this.chartInstance.getCurrentChart(),
        this.$refs.exportCanvas,
        this.chartData,
        this.options,
        this.imageSize
      )

      if (chart) {
        exportService.savePng(chart, 'chart.png')
        // Hide the export wrapper after exporting
        this.$refs.exportWrapper.style.display = 'none'
        // Remove export chart instance
        chart.destroy()
      }
    },

    switchChartView() {
      this.expanded = !this.expanded
    },

    onChartMounted(chartRef) {
      this.chartInstance = chartRef
    },

    onChartRender(chartRef) {
      const chart = chartRef.getCurrentChart()

      if (chart?.legend?.legendHitBoxes?.length > 0 && !this.canExpand) {
        this.canExpand = _.max(chart.legend.legendHitBoxes.map((box) => box.top)) > chart.legend.bottom
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-exportable-pie-chart {
  position: relative;

  & .action-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    height: auto;
    padding-top: 10px;
  }

  & .chart-container {
    position: relative;
    transition: width 0.5s ease, height 0.5s ease;
  }

  & .expand-info {
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding-top: 2px;
    color: rgb(106, 106, 106);
    cursor: pointer;
  }

  & .chart-container.expanded {
    height: 35vw !important;
  }

  & .export-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    visibility: hidden;
  }
}
</style>
