<i18n>
{
  "de": {
    "displayBuildingCount": "{total} Liegenschaft | {total} Liegenschaften",
    "displayFilteredBuildingCount": "{filtered} von {total} Liegenschaften",
    "pendingMessage": "Die Liegenschaften werden gelöscht, dieser Vorgang kann einen Moment dauern, bitte warten Sie.",
    "noBuildingsFound": "Dieses Portfolio ist noch leer.",
    "noBuildingsMatchQuery": "Keine Treffer für diesen Filter.",
    "addBuildingButton": "Liegenschaft hinzufügen",
    "sortReverseButton": "Sortierung umkehren",
    "exportPortfolioButton": "Portfolio export (CSV)",
    "deleteConfirmation": "Wollen Sie alle Liegenschaften löschen?",
    "removeBuildingsButton": "Alle Liegenschaften löschen",
    "importBuilding": "Liegenschaft importieren"
  }
}
</i18n>

<template>
  <div v-if="error">
    {{ error }}
  </div>
  <div v-else-if="pending">
    {{ $t('pendingMessage') }}
  </div>
  <div v-else class="c-portfolio-list">
    <div class="portfolio-controls">
      <div class="portfolio-controls__count">
        <ClipLoader v-if="buildingsLoading" size="18px" color="#000" />
        <div v-else class="portfolio-summary">
          <span>{{ numberOfBuildingsText }}</span>
          <div class="portfolio-summary-info-box">
            <SummaryInfoBox v-if="!buildingsLoading" :summary-data="portfolioSummary" filterable />
          </div>
        </div>
      </div>
      <div class="portfolio-controls__sorting">
        <SortDropdown :portfolio="portfolio" />

        <VPopover trigger="hover">
          <SortReverse :portfolio="portfolio" />
          <template slot="popover">
            <main>{{ $t('sortReverseButton') }}</main>
          </template>
        </VPopover>

        <VPopover trigger="hover">
          <button
            type="button"
            class="button"
            :disabled="exportLoading"
            @click="exportPortfolioBuildings(portfolio.id)"
          >
            <img v-if="!exportLoading" class="icon" src="/icons/download.svg" />
            <ClipLoader v-else class="export-spinner" size="18px" color="#000" />
          </button>
          <template slot="popover">
            <main>{{ $t('exportPortfolioButton') }}</main>
          </template>
        </VPopover>

        <template v-if="getPortfolioPermission('EDIT_BUILDINGS')">
          <VPopover trigger="hover">
            <router-link class="button" :to="{ name: 'addBuilding', params: { portfolio_id: portfolio.id } }">
              <img class="icon" src="/icons/plus.svg" />
            </router-link>
            <template slot="popover">
              <main>{{ $t('addBuildingButton') }}</main>
            </template>
          </VPopover>
        </template>

        <!-- Toggle cards vs line items -->
        <nav class="round-buttons">
          <a href="#" :class="{ selected: displayMode === 'cards' }" @click.prevent="setDisplayMode('cards')">
            <div>
              <img src="/icons/credit-card.svg" />
            </div>
          </a>
          <a href="#" :class="{ selected: displayMode === 'table' }" @click.prevent="setDisplayMode('table')">
            <div>
              <img src="/icons/align-justify.svg" />
            </div>
          </a>
        </nav>
      </div>
    </div>

    <ul v-if="buildings && buildings.length" :class="{ 'portfolio-table': displayMode === 'table' }">
      <PortfolioListItem
        v-for="building in buildings"
        :key="building.id"
        :portfolio="portfolio"
        :building="building"
        :search-text="searchText"
        :display-mode="displayMode"
      />
    </ul>

    <div v-if="!buildingsLoading && totalNumberOfBuildings === 0">
      <div>{{ $t('noBuildingsFound') }}</div>
    </div>

    <div v-else-if="!buildingsLoading && filteredNumberOfBuildings === 0">
      <div>{{ $t('noBuildingsMatchQuery') }}</div>
    </div>

    <ButtonWrapper v-if="getPortfolioPermission('EDIT_BUILDINGS')">
      <router-link class="button" :to="{ name: 'addBuilding', params: { portfolio_id: portfolio.id } }">{{
        $t('addBuildingButton')
      }}</router-link>
      <button class="button" @click.prevent="onImportBuilding">{{ $t('importBuilding') }}</button>
      <ImportBuildingModal v-if="importModalOpen" :portfolio="portfolio" @close="onImportBuildingClose" />
      <button v-if="buildings.length > 0" class="button" @click="onDeleteAllBuildings">
        {{ $t('removeBuildingsButton') }}
      </button>
    </ButtonWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import _ from 'lodash'

import { VPopover } from 'v-tooltip'

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import ImportBuildingModal from '@/components/portfolio/ImportBuildingModal.vue'
import PortfolioListItem from '@/components/portfolio/PortfolioListItem.vue'
import SortDropdown from '@/components/portfolio/SortDropdown.vue'
import SortReverse from '@/components/portfolio/SortReverse.vue'
import SummaryInfoBox from '@/components/portfolio/SummaryInfoBox.vue'

export default {
  components: {
    ButtonWrapper,
    PortfolioListItem,
    SortDropdown,
    SortReverse,
    ImportBuildingModal,
    VPopover,
    ClipLoader,
    SummaryInfoBox,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    searchText: {
      type: String,
      required: false,
    },
    asTable: {
      type: Boolean,
      default: false,
    },
    buildings: {
      type: Array,
      required: true,
    },
    buildingsLoading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      pending: false,
      importModalOpen: false,
    }
  },

  computed: {
    ...mapState('portfolio', ['defaultSustainabilityIndicatorIdentifiers', 'exportLoading']),
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    filteredNumberOfBuildings() {
      return this.buildings.length
    },

    totalNumberOfBuildings() {
      return this.portfolio?.summary?.total || 0
    },

    portfolioSummary() {
      const portfolioStatusCounts = this.portfolio?.summary?.status_counts || {}
      const filteredStatusCounts = _(this.buildings || [])
        .countBy('status')
        .mapKeys((value, key) => key.toLowerCase())
        .value()

      return {
        total: [this.filteredNumberOfBuildings, this.totalNumberOfBuildings],
        status_counts: {
          planner: [filteredStatusCounts.planner || 0, portfolioStatusCounts.planner || 0],
          reporter: [filteredStatusCounts.reporter || 0, portfolioStatusCounts.reporter || 0],
          archived: [filteredStatusCounts.archived || 0, portfolioStatusCounts.archived || 0],
        },
      }
    },

    numberOfBuildingsText() {
      const filtered = this.filteredNumberOfBuildings
      const total = this.totalNumberOfBuildings

      return total === filtered
        ? this.$tc('displayBuildingCount', total, { total })
        : this.$t('displayFilteredBuildingCount', { filtered, total })
    },

    displayMode() {
      return this.$store.state.ui.portfolioListDisplayMode
    },
  },

  created() {
    const mode = localStorage.getItem('portfolioListDisplayMode')
    if (mode) {
      this.$store.dispatch('ui/setPortfolioListDisplayMode', mode)
    }
  },

  mounted() {
    this.refreshDetailsById(this.portfolio.id)
  },

  methods: {
    ...mapActions({
      deleteBuildings: 'building/deleteBuildings',
      exportPortfolioBuildings: 'portfolio/exportPortfolioBuildings',
      refreshDetailsById: 'portfolio/refreshDetailsById',
    }),

    //
    async onDeleteAllBuildings() {
      this.pending = true
      const confirmationText = this.$t('deleteConfirmation')
      if (confirm(confirmationText)) {
        try {
          this.error = null
          await this.deleteBuildings({
            buildingIds: this.buildings.map((building) => building.id),
            portfolioId: this.portfolio.id,
          })
        } catch (error) {
          this.error = error
        }
      }
      this.pending = false
    },

    //
    onImportBuilding() {
      this.importModalOpen = true
    },

    //
    onImportBuildingClose() {
      this.importModalOpen = false
    },

    // Show cards or line items
    setDisplayMode(mode) {
      this.$store.dispatch('ui/setPortfolioListDisplayMode', mode)
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-list {
  & .portfolio-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-s) 0;
    margin-bottom: var(--spacing-s);

    & .portfolio-summary {
      display: flex;
      gap: var(--spacing-xxs);

      & .portfolio-summary-info-box {
        position: relative;
        top: -2px;
      }
    }
  }

  & .portfolio-controls__sorting {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-s);

    & .export-spinner {
      width: 24px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & > ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    gap: var(--spacing-m);

    & > li {
      width: calc((100% / 6) - (var(--spacing-m) * 5 / 6));

      @media screen and (max-width: 2160px) {
        width: calc((100% / 5) - (var(--spacing-m) * 4 / 5));
      }

      @media screen and (max-width: 1920px) {
        width: calc((100% / 4) - (var(--spacing-m) * 3 / 4));
      }

      @media screen and (max-width: 1440px) {
        width: calc((100% / 3) - (var(--spacing-m) * 2 / 3));
      }

      @media screen and (max-width: 1200px) {
        width: calc((100% / 4) - (var(--spacing-m) * 3 / 4));
      }

      @media screen and (max-width: 1080px) {
        width: calc((100% / 3) - (var(--spacing-m) * 2 / 3));
      }

      @media screen and (max-width: 767px) {
        width: calc((100% / 2) - (var(--spacing-m) / 2));
      }

      @media screen and (max-width: 600px) {
        width: 100%;
      }

      & > a {
        border-radius: var(--box-radius);
        border: var(--box-border);
        transition: box-shadow 0.2s ease-in-out;

        &:hover {
          box-shadow: 0px 8px 16px -2px #eee;
        }
      }
    }
  }

  & > ul {
    &.portfolio-table {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 0;
      border-radius: var(--box-radius);
      border: var(--box-border);
      overflow: auto;
      max-height: 65svh;

      @media screen and (max-width: 1440px) {
        max-height: 45svh;
      }

      @media screen and (max-width: 1200px) {
        max-height: 65svh;
      }

      & > li {
        width: 100%;
        min-width: fit-content;

        & > a {
          width: 100%;
          min-width: fit-content;
          border-radius: 0;
          border: none;
          border-bottom: 1px solid #ddd;

          &:hover {
            box-shadow: none;
          }

          & main {
            width: 100%;
            min-width: fit-content;

            & > * {
              flex: none;
            }
          }
        }

        &:last-child {
          & > a {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
