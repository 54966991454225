<i18n>
{
  "de": {
    "groupByTypes": {
      "carrier": "Energieträger",
      "usage": "Verwendungszweck"
    },
    "kpiTypes": {
      "ee": "Endenergie (MWh)"
    },
    "units": {
      "ee": "MWh",
      "s12e": "t",
      "s123e": "t",
      "per": "MWh"
    }
  }
}
</i18n>

<template>
  <ExportablePieChart
    :chart-data="processedChartData"
    :options="options"
    :grouping-name="$t(`groupByTypes.${groupByType}`)"
    class="kpi-pie-chart"
  />
</template>

<script>
import _ from 'lodash'

import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import ExportablePieChart from '@/components/shared/charts/ExportablePieChart.vue'

import colorPalettes from '@/services/color-palettes.js'
import { mapGetters } from 'vuex'

export default {
  mixins: [ChartOptionsMixin],

  components: {
    ExportablePieChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    kpiType: {
      type: String, // 's12e', 's123e', 's1e', 's2e', 's3e', 'ee', 'pe', 'per', 'penr'
      required: true,
    },
    groupByType: {
      // 'carrier' or 'usage'
      type: String,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.kpiType)
    },

    groupedValues() {
      const self = this
      const values = _.values(
        this.chartData.reduce(function (res, b) {
          let group
          if (b['kpis'][`${self.kpiType}_by_${self.groupByType}`]) {
            group = b['kpis'][`${self.kpiType}_by_${self.groupByType}`]
          } else {
            group = b[`${self.kpiType}_by_${self.groupByType}`]
          }
          if (group !== undefined && group !== null) {
            for (const [groupKey, groupValue] of _.entries(group)) {
              if (!res[groupKey]) {
                res[groupKey] = { key: groupKey, result: 0 }
              }
              res[groupKey].result += groupValue / 1000 // Convert kg to t and kWh to MWh
            }
          }
          return res
        }, {})
      )

      // If the groupByType is 'carrier', sort the values from largest to smallest
      // If the groupByType is 'usage', sort the items in this order: 'ROOM_HEAT', 'HOT_WATER', 'GENERAL', 'TENANTS'
      // and leave out 'FEED_IN'
      return this.groupByType === 'carrier'
        ? _.sortBy(values, 'result').reverse()
        : values
            .filter((v) => v.key !== 'FEED_IN')
            .sort((a, b) => {
              const order = ['ROOM_HEAT', 'HOT_WATER', 'GENERAL', 'TENANTS']
              return order.indexOf(a.key) - order.indexOf(b.key)
            })
    },

    labels() {
      if (this.groupByType === 'usage') {
        return this.groupedValues.map((g) => this.$t(`_energyUsages.${g.key}`))
      } else if (this.groupByType === 'carrier') {
        return this.groupedValues.map((g) => {
          if (g.key === 'ELECTRICITY_GRID') return this.$t('_energyCarriers.ELECTRICITY_GRID')
          if (g.key === 'ELECTRICITY_OWNUSE') return this.$t('_energyCarriers.ELECTRICITY_OWNUSE')
          if (g.key === 'ELECTRICITY_FEEDIN') return this.$t('_energyCarriers.ELECTRICITY_FEEDIN')
          return this.portfolio.getEnergyCarrierName(
            (k) => this.$t(k),
            this.portfolio.energy_carriers.findIndex((e) => e.name === g.key)
          )
        })
      } else {
        return undefined
      }
    },

    bigUnit() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`units.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.getBigTargetUnit()
    },

    getIndicatorName() {
      if (!this.sustainabilityIndicator) {
        return this.kpiType
      }
      return this.sustainabilityIndicator.name
    },

    colors() {
      if (this.groupByType === 'usage') {
        return this.groupedValues.map((g) => colorPalettes.usageColors[g.key])
      } else if (this.groupByType === 'carrier') {
        return (
          this.groupedValues &&
          this.groupedValues.map((g) => {
            if (g.key in colorPalettes.carrierColors) {
              return colorPalettes.carrierColors[g.key]
            } else {
              return '#' + Math.floor(Math.random() * 16777215).toString(16) // https://css-tricks.com/snippets/javascript/random-hex-color/
            }
          })
        )
      } else {
        return undefined
      }
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            display: false,
          },
          yAxis: {
            display: false,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            display: true,
          },
          tooltip: {
            callbacks: {
              label: (item) => this.getTooltipLabel(item, { precision: 1, unit: this.bigUnit, label: item.label }),
            },
          },
        },
      }
    },

    processedChartData() {
      return {
        datasets: [
          {
            label: `${this.getIndicatorName} (${this.bigUnit})`,
            data: this.groupedValues.map((g) => g.result),
            hoverOffset: 4,
            backgroundColor: this.colors,
          },
        ],
        labels: this.labels,
      }
    },
  },
}
</script>
