<!--
Provides: chartData, chartDataLoading, chartDataReady
Requires: portfolio_id
-->
<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState({
      chartDataLoading: (state) => state.chartData.loading,
      chartDataLoaded: (state) => state.chartData.loaded,
    }),

    ...mapGetters({
      __chartData: 'chartData/getChartData',
    }),

    chartData() {
      return this.__chartData()
    },
  },

  watch: {
    portfolio_id() {
      this.__loadChartData({ portfolioId: this.portfolio_id })
    },
  },

  created() {
    this.__loadChartData({ portfolioId: this.portfolio_id })
  },

  methods: {
    ...mapActions({
      __loadChartData: 'chartData/load',
    }),
  },
}
</script>
