<i18n>
{
  "de": {
    "yLabel": "Scope 1-2 Emissionen* (kg CO₂e/m²)",
    "xLabel": "Endenergie* (kWh/m²)",
    "emissionsTooltip": "Scope 1-2 Emissionen*",
    "emissionsUnitsTooltip": "kg CO₂e/m²",
    "energyTooltip": "Endenergie*",
    "energyUnitsTooltip": "kWh/m²",
    "withoutTenantSideNote": "*Mieterseitige Energie und mieterseitige Emissionen werden hier nicht miteingerechnet."
  }
}
</i18n>

<template>
  <div class="c-bubble-chart-wrapper">
    <BubbleChart :chart-data="processedChartData" :options="options" class="bubble-chart" />
    <span class="footnote">{{ $t('withoutTenantSideNote') }}</span>
  </div>
</template>

<script>
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import BubbleChart from '@/components/shared/charts/BubbleChart.vue'
import _ from 'lodash'

export default {
  mixins: [ChartOptionsMixin],

  components: {
    BubbleChart,
  },

  props: {
    show: {
      type: Boolean,
      default: true,
    },
    highlightBuildingId: {
      type: Number,
      required: false,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.onChartClick,
        scales: {
          xAxis: {
            title: {
              display: true,
              text: this.$t('xLabel'),
            },
            beginAtZero: true,
          },
          yAxis: {
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
            beginAtZero: true,
          },
        },
        plugins: {
          tooltip: {
            displayColors: false,
            callbacks: {
              title: this.getTooltipTitleWithIdentifier,
              beforeBody: this.beforeBody,
              beforeLabel: (item) =>
                this.getTooltipLabel(item, {
                  unit: this.$t('energyUnitsTooltip'),
                  label: this.$t('energyTooltip'),
                  axis: 'x',
                }),
              label: (item) =>
                this.getTooltipLabel(item, {
                  unit: this.$t('emissionsUnitsTooltip'),
                  label: this.$t('emissionsTooltip'),
                }),
            },
          },
          legend: {
            display: false,
          },
        },
      }
    },

    processedChartData() {
      let datasets = []

      let data = []
      for (let i = 0; i < this.chartData.length; i++) {
        const building = this.chartData[i]
        // TODO: Scale radius automatically according to set distribution
        data.push({
          r: Math.min(20, Math.sqrt(building.energy_area) / 7),
          x: this.getKpiWithoutTenantSide(building.kpis.ee_by_usage) / building.energy_area,
          y: this.getKpiWithoutTenantSide(building.kpis.s12e_by_usage) / building.energy_area,
        })
      }
      const highlightBuildingId = this.highlightBuildingId
      datasets.push({
        backgroundColor: this.chartData.map((b) =>
          b.id === highlightBuildingId
            ? 'hsla(202, 92%, 24%, 100%)'
            : b.selected
            ? 'hsla(202, 92%, 14%, 10%)'
            : 'hsla(202, 92%, 14%, 8%)'
        ),
        borderColor: this.chartData.map((b) => (b.selected ? 'hsla(202, 92%, 14%, 30%)' : 'hsla(202, 92%, 14%, 10%)')),
        hoverBackgroundColor: 'hsla(20, 92%, 44%, 25%)',
        hoverBorderColor: 'hsla(20, 92%, 44%, 70%)',
        data: data,
      })

      return {
        datasets: datasets,
      }
    },
  },

  methods: {
    beforeBody(items) {
      return `${this.chartData[items[0].dataIndex].description}, ${
        this.chartData[items[0].dataIndex].municipality === null ? '' : this.chartData[items[0].dataIndex].municipality
      }`
    },
    getKpiWithoutTenantSide(byUsage) {
      return _.entries(byUsage)
          .filter((e) => e[0] !== 'TENANTS')
          .reduce((sum, e) => sum + e[1], 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-bubble-chart-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > * {
    width: 100%;
  }

  & .footnote {
    padding-top: 10px;
    font-size: var(--font-xs);
    color: #888;
    text-align: center;
  }
}
</style>
